@import url(https://fonts.googleapis.com/css2?family=Arya&family=Inter&family=Montserrat:wght@400;700&family=Playfair+Display:wght@700&family=Poppins:wght@400;500;700&display=swap);
/*** Feel free to use this mixin on your site, however pleae be sure to give 
credit for my work by linking back to www.tundratech.ca or this pen please. 

Please excuse my current crappy website, it is years out of date.
I have a massive portfolio and redesign I will begin March 31, 2014.
I will also be availiable for hire after this date.

This pen has been a desire of mine ever since I once saw full color aurora
during the time when HALE-BOPP was visiting. Usually aurora is blue-green 
due to heavy nitrogen in the atmosphere. Full coloraurora come from oxygen 
and usually appear further south (but the south is less likely to see aurora).

Questions? Comments? Suggestion for performance improvement?

DJ Sumanik
www.tundratech.ca
dj@tundratech.ca

***/
body {
  background: #000 url("http://i1.ytimg.com/vi/T40NSkd7Olc/maxresdefault.jpg");
  margin: 0;
  padding: 0;
}

.css-10zcp63 {
  background-color: black !important;
  /* position: fixed !important; */
}

.css-1qniskg {
  background-color: black !important;

  /* position: fixed !important; */
}
.ray {
  position: absolute;
  top: 0;
  border-radius: 20px;
  z-index: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -khtml-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.ray:nth-child(1) {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9) 7%,
    rgba(0, 0, 0, 0) 25%,
    black 90%
  );
}
.ray:nth-child(2) {
  height: 20px;
  width: 100%;
  position: absolute;
  margin: auto;
  top: -20px;
  left: 0;
  z-index: 9999;
  opacity: 0.7;
  border-radius: 50%;
  -webkit-animation: light-field 42000ms ease-in-out infinite alternate;
  animation: light-field 42000ms ease-in-out infinite alternate;
}
.ray:nth-child(3) {
  left: 44%;
  height: 179px;
  margin-top: -100px;
  width: 3px;
  background: #ff5900;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6646ms infinite,
    wiggle3 ease-in-out 6066ms infinite;
  animation: sml-fade ease-in-out 6646ms infinite,
    wiggle3 ease-in-out 6066ms infinite;
}
.ray:nth-child(4) {
  left: 14%;
  height: 190px;
  margin-top: -100px;
  width: 3px;
  background: #ff7700;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6685ms infinite,
    wiggle2 ease-in-out 6961ms infinite;
  animation: sml-fade ease-in-out 6685ms infinite,
    wiggle2 ease-in-out 6961ms infinite;
}
.ray:nth-child(5) {
  left: 39%;
  height: 370px;
  margin-top: -100px;
  width: 3px;
  background: #ff9500;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6883ms infinite,
    wiggle2 ease-in-out 6167ms infinite;
  animation: sml-fade ease-in-out 6883ms infinite,
    wiggle2 ease-in-out 6167ms infinite;
}
.ray:nth-child(6) {
  left: 75%;
  height: 426px;
  margin-top: -100px;
  width: 4px;
  background: #ffb300;
  -webkit-filter: blur(12.4px);
  -khtml-filter: blur(12.4px);
  -moz-filter: blur(12.4px);
  -ms-filter: blur(12.4px);
  -o-filter: blur(12.4px);
  filter: blur(12.4px);
  -webkit-animation: sml-fade ease-in-out 6162ms infinite,
    wiggle2 ease-in-out 6078ms infinite;
  animation: sml-fade ease-in-out 6162ms infinite,
    wiggle2 ease-in-out 6078ms infinite;
}
.ray:nth-child(7) {
  left: 42%;
  height: 275px;
  margin-top: -100px;
  width: 3px;
  background: #ffd000;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6668ms infinite,
    wiggle2 ease-in-out 6973ms infinite;
  animation: sml-fade ease-in-out 6668ms infinite,
    wiggle2 ease-in-out 6973ms infinite;
}
.ray:nth-child(8) {
  left: 31%;
  height: 216px;
  margin-top: -100px;
  width: 3px;
  background: #ffee00;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6775ms infinite,
    wiggle2 ease-in-out 6748ms infinite;
  animation: sml-fade ease-in-out 6775ms infinite,
    wiggle2 ease-in-out 6748ms infinite;
}
.ray:nth-child(9) {
  left: 70%;
  height: 171px;
  margin-top: -100px;
  width: 3px;
  background: #f2ff00;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6508ms infinite,
    wiggle3 ease-in-out 6882ms infinite;
  animation: sml-fade ease-in-out 6508ms infinite,
    wiggle3 ease-in-out 6882ms infinite;
}
.ray:nth-child(10) {
  left: 8%;
  height: 109px;
  margin-top: -100px;
  width: 4px;
  background: #d5ff00;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6805ms infinite,
    wiggle1 ease-in-out 6103ms infinite;
  animation: sml-fade ease-in-out 6805ms infinite,
    wiggle1 ease-in-out 6103ms infinite;
}
.ray:nth-child(11) {
  left: 29%;
  height: 404px;
  margin-top: -100px;
  width: 3px;
  background: #b7ff00;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6339ms infinite,
    wiggle1 ease-in-out 6371ms infinite;
  animation: sml-fade ease-in-out 6339ms infinite,
    wiggle1 ease-in-out 6371ms infinite;
}
.ray:nth-child(12) {
  left: 18%;
  height: 347px;
  margin-top: -100px;
  width: 4px;
  background: #99ff00;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6361ms infinite,
    wiggle3 ease-in-out 6832ms infinite;
  animation: sml-fade ease-in-out 6361ms infinite,
    wiggle3 ease-in-out 6832ms infinite;
}
.ray:nth-child(13) {
  left: 7%;
  height: 262px;
  margin-top: -100px;
  width: 4px;
  background: #7bff00;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 6360ms infinite,
    wiggle2 ease-in-out 6263ms infinite;
  animation: sml-fade ease-in-out 6360ms infinite,
    wiggle2 ease-in-out 6263ms infinite;
}
.ray:nth-child(14) {
  left: 19%;
  height: 303px;
  margin-top: -100px;
  width: 4px;
  background: #5eff00;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 6162ms infinite,
    wiggle1 ease-in-out 6128ms infinite;
  animation: sml-fade ease-in-out 6162ms infinite,
    wiggle1 ease-in-out 6128ms infinite;
}
.ray:nth-child(15) {
  left: 93%;
  height: 145px;
  margin-top: -100px;
  width: 4px;
  background: #40ff00;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6100ms infinite,
    wiggle2 ease-in-out 6657ms infinite;
  animation: sml-fade ease-in-out 6100ms infinite,
    wiggle2 ease-in-out 6657ms infinite;
}
.ray:nth-child(16) {
  left: 2%;
  height: 271px;
  margin-top: -100px;
  width: 3px;
  background: #22ff00;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6997ms infinite,
    wiggle3 ease-in-out 6263ms infinite;
  animation: sml-fade ease-in-out 6997ms infinite,
    wiggle3 ease-in-out 6263ms infinite;
}
.ray:nth-child(17) {
  left: 75%;
  height: 122px;
  margin-top: -100px;
  width: 3px;
  background: #04ff00;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6779ms infinite,
    wiggle2 ease-in-out 6021ms infinite;
  animation: sml-fade ease-in-out 6779ms infinite,
    wiggle2 ease-in-out 6021ms infinite;
}
.ray:nth-child(18) {
  left: 33%;
  height: 123px;
  margin-top: -100px;
  width: 4px;
  background: #00ff1a;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6662ms infinite,
    wiggle2 ease-in-out 6650ms infinite;
  animation: sml-fade ease-in-out 6662ms infinite,
    wiggle2 ease-in-out 6650ms infinite;
}
.ray:nth-child(19) {
  left: 26%;
  height: 328px;
  margin-top: -100px;
  width: 4px;
  background: #00ff37;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 6250ms infinite,
    wiggle2 ease-in-out 6136ms infinite;
  animation: sml-fade ease-in-out 6250ms infinite,
    wiggle2 ease-in-out 6136ms infinite;
}
.ray:nth-child(20) {
  left: 10%;
  height: 302px;
  margin-top: -100px;
  width: 4px;
  background: #00ff55;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 6887ms infinite,
    wiggle1 ease-in-out 6613ms infinite;
  animation: sml-fade ease-in-out 6887ms infinite,
    wiggle1 ease-in-out 6613ms infinite;
}
.ray:nth-child(21) {
  left: 83%;
  height: 351px;
  margin-top: -100px;
  width: 4px;
  background: #00ff73;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6170ms infinite,
    wiggle3 ease-in-out 6026ms infinite;
  animation: sml-fade ease-in-out 6170ms infinite,
    wiggle3 ease-in-out 6026ms infinite;
}
.ray:nth-child(22) {
  left: 22%;
  height: 343px;
  margin-top: -100px;
  width: 3px;
  background: #00ff91;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6927ms infinite,
    wiggle3 ease-in-out 6874ms infinite;
  animation: sml-fade ease-in-out 6927ms infinite,
    wiggle3 ease-in-out 6874ms infinite;
}
.ray:nth-child(23) {
  left: 26%;
  height: 425px;
  margin-top: -100px;
  width: 4px;
  background: #00ffae;
  -webkit-filter: blur(12.4px);
  -khtml-filter: blur(12.4px);
  -moz-filter: blur(12.4px);
  -ms-filter: blur(12.4px);
  -o-filter: blur(12.4px);
  filter: blur(12.4px);
  -webkit-animation: sml-fade ease-in-out 6502ms infinite,
    wiggle1 ease-in-out 6985ms infinite;
  animation: sml-fade ease-in-out 6502ms infinite,
    wiggle1 ease-in-out 6985ms infinite;
}
.ray:nth-child(24) {
  left: 30%;
  height: 404px;
  margin-top: -100px;
  width: 3px;
  background: #00ffcc;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6587ms infinite,
    wiggle2 ease-in-out 6238ms infinite;
  animation: sml-fade ease-in-out 6587ms infinite,
    wiggle2 ease-in-out 6238ms infinite;
}
.ray:nth-child(25) {
  left: 63%;
  height: 121px;
  margin-top: -100px;
  width: 3px;
  background: #00ffea;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6129ms infinite,
    wiggle1 ease-in-out 6585ms infinite;
  animation: sml-fade ease-in-out 6129ms infinite,
    wiggle1 ease-in-out 6585ms infinite;
}
.ray:nth-child(26) {
  left: 87%;
  height: 192px;
  margin-top: -100px;
  width: 4px;
  background: #00f7ff;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6204ms infinite,
    wiggle2 ease-in-out 6647ms infinite;
  animation: sml-fade ease-in-out 6204ms infinite,
    wiggle2 ease-in-out 6647ms infinite;
}
.ray:nth-child(27) {
  left: 81%;
  height: 195px;
  margin-top: -100px;
  width: 3px;
  background: #00d9ff;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6541ms infinite,
    wiggle1 ease-in-out 6860ms infinite;
  animation: sml-fade ease-in-out 6541ms infinite,
    wiggle1 ease-in-out 6860ms infinite;
}
.ray:nth-child(28) {
  left: 5%;
  height: 350px;
  margin-top: -100px;
  width: 4px;
  background: #00bbff;
  -webkit-filter: blur(12.4px);
  -khtml-filter: blur(12.4px);
  -moz-filter: blur(12.4px);
  -ms-filter: blur(12.4px);
  -o-filter: blur(12.4px);
  filter: blur(12.4px);
  -webkit-animation: sml-fade ease-in-out 6689ms infinite,
    wiggle3 ease-in-out 6889ms infinite;
  animation: sml-fade ease-in-out 6689ms infinite,
    wiggle3 ease-in-out 6889ms infinite;
}
.ray:nth-child(29) {
  left: 85%;
  height: 366px;
  margin-top: -100px;
  width: 3px;
  background: #009dff;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6776ms infinite,
    wiggle1 ease-in-out 6989ms infinite;
  animation: sml-fade ease-in-out 6776ms infinite,
    wiggle1 ease-in-out 6989ms infinite;
}
.ray:nth-child(30) {
  left: 18%;
  height: 297px;
  margin-top: -100px;
  width: 4px;
  background: #0080ff;
  -webkit-filter: blur(12.4px);
  -khtml-filter: blur(12.4px);
  -moz-filter: blur(12.4px);
  -ms-filter: blur(12.4px);
  -o-filter: blur(12.4px);
  filter: blur(12.4px);
  -webkit-animation: sml-fade ease-in-out 6643ms infinite,
    wiggle3 ease-in-out 6214ms infinite;
  animation: sml-fade ease-in-out 6643ms infinite,
    wiggle3 ease-in-out 6214ms infinite;
}
.ray:nth-child(31) {
  left: 8%;
  height: 116px;
  margin-top: -100px;
  width: 4px;
  background: #0062ff;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 7000ms infinite,
    wiggle3 ease-in-out 6682ms infinite;
  animation: sml-fade ease-in-out 7000ms infinite,
    wiggle3 ease-in-out 6682ms infinite;
}
.ray:nth-child(32) {
  left: 91%;
  height: 295px;
  margin-top: -100px;
  width: 3px;
  background: #0044ff;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6591ms infinite,
    wiggle1 ease-in-out 6605ms infinite;
  animation: sml-fade ease-in-out 6591ms infinite,
    wiggle1 ease-in-out 6605ms infinite;
}
.ray:nth-child(33) {
  left: 23%;
  height: 347px;
  margin-top: -100px;
  width: 4px;
  background: #0026ff;
  -webkit-filter: blur(12.4px);
  -khtml-filter: blur(12.4px);
  -moz-filter: blur(12.4px);
  -ms-filter: blur(12.4px);
  -o-filter: blur(12.4px);
  filter: blur(12.4px);
  -webkit-animation: sml-fade ease-in-out 6607ms infinite,
    wiggle2 ease-in-out 6067ms infinite;
  animation: sml-fade ease-in-out 6607ms infinite,
    wiggle2 ease-in-out 6067ms infinite;
}
.ray:nth-child(34) {
  left: 60%;
  height: 211px;
  margin-top: -100px;
  width: 3px;
  background: #0009ff;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6396ms infinite,
    wiggle3 ease-in-out 6849ms infinite;
  animation: sml-fade ease-in-out 6396ms infinite,
    wiggle3 ease-in-out 6849ms infinite;
}
.ray:nth-child(35) {
  left: 69%;
  height: 370px;
  margin-top: -100px;
  width: 4px;
  background: #1500ff;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6788ms infinite,
    wiggle2 ease-in-out 6350ms infinite;
  animation: sml-fade ease-in-out 6788ms infinite,
    wiggle2 ease-in-out 6350ms infinite;
}
.ray:nth-child(36) {
  left: 52%;
  height: 170px;
  margin-top: -100px;
  width: 3px;
  background: #3300ff;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6176ms infinite,
    wiggle3 ease-in-out 6285ms infinite;
  animation: sml-fade ease-in-out 6176ms infinite,
    wiggle3 ease-in-out 6285ms infinite;
}
.ray:nth-child(37) {
  left: 76%;
  height: 214px;
  margin-top: -100px;
  width: 4px;
  background: #5100ff;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 6291ms infinite,
    wiggle2 ease-in-out 6160ms infinite;
  animation: sml-fade ease-in-out 6291ms infinite,
    wiggle2 ease-in-out 6160ms infinite;
}
.ray:nth-child(38) {
  left: 17%;
  height: 337px;
  margin-top: -100px;
  width: 3px;
  background: #6f00ff;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6210ms infinite,
    wiggle2 ease-in-out 6409ms infinite;
  animation: sml-fade ease-in-out 6210ms infinite,
    wiggle2 ease-in-out 6409ms infinite;
}
.ray:nth-child(39) {
  left: 29%;
  height: 368px;
  margin-top: -100px;
  width: 3px;
  background: #8c00ff;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6506ms infinite,
    wiggle2 ease-in-out 6882ms infinite;
  animation: sml-fade ease-in-out 6506ms infinite,
    wiggle2 ease-in-out 6882ms infinite;
}
.ray:nth-child(40) {
  left: 18%;
  height: 308px;
  margin-top: -100px;
  width: 3px;
  background: #aa00ff;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6458ms infinite,
    wiggle2 ease-in-out 6841ms infinite;
  animation: sml-fade ease-in-out 6458ms infinite,
    wiggle2 ease-in-out 6841ms infinite;
}
.ray:nth-child(41) {
  left: 31%;
  height: 293px;
  margin-top: -100px;
  width: 4px;
  background: #c800ff;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 6333ms infinite,
    wiggle2 ease-in-out 6530ms infinite;
  animation: sml-fade ease-in-out 6333ms infinite,
    wiggle2 ease-in-out 6530ms infinite;
}
.ray:nth-child(42) {
  left: 24%;
  height: 201px;
  margin-top: -100px;
  width: 3px;
  background: #e600ff;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6396ms infinite,
    wiggle1 ease-in-out 6202ms infinite;
  animation: sml-fade ease-in-out 6396ms infinite,
    wiggle1 ease-in-out 6202ms infinite;
}
.ray:nth-child(43) {
  left: 49%;
  height: 248px;
  margin-top: -100px;
  width: 3px;
  background: #ff00fb;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6511ms infinite,
    wiggle2 ease-in-out 6707ms infinite;
  animation: sml-fade ease-in-out 6511ms infinite,
    wiggle2 ease-in-out 6707ms infinite;
}
.ray:nth-child(44) {
  left: 37%;
  height: 272px;
  margin-top: -100px;
  width: 4px;
  background: #ff00dd;
  -webkit-filter: blur(11.4px);
  -khtml-filter: blur(11.4px);
  -moz-filter: blur(11.4px);
  -ms-filter: blur(11.4px);
  -o-filter: blur(11.4px);
  filter: blur(11.4px);
  -webkit-animation: sml-fade ease-in-out 6651ms infinite,
    wiggle3 ease-in-out 6486ms infinite;
  animation: sml-fade ease-in-out 6651ms infinite,
    wiggle3 ease-in-out 6486ms infinite;
}
.ray:nth-child(45) {
  left: 64%;
  height: 156px;
  margin-top: -100px;
  width: 4px;
  background: #ff00bf;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6335ms infinite,
    wiggle1 ease-in-out 6996ms infinite;
  animation: sml-fade ease-in-out 6335ms infinite,
    wiggle1 ease-in-out 6996ms infinite;
}
.ray:nth-child(46) {
  left: 87%;
  height: 370px;
  margin-top: -100px;
  width: 4px;
  background: #ff00a2;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6363ms infinite,
    wiggle1 ease-in-out 6061ms infinite;
  animation: sml-fade ease-in-out 6363ms infinite,
    wiggle1 ease-in-out 6061ms infinite;
}
.ray:nth-child(47) {
  left: 81%;
  height: 137px;
  margin-top: -100px;
  width: 4px;
  background: #ff0084;
  -webkit-filter: blur(10.4px);
  -khtml-filter: blur(10.4px);
  -moz-filter: blur(10.4px);
  -ms-filter: blur(10.4px);
  -o-filter: blur(10.4px);
  filter: blur(10.4px);
  -webkit-animation: sml-fade ease-in-out 6608ms infinite,
    wiggle2 ease-in-out 6715ms infinite;
  animation: sml-fade ease-in-out 6608ms infinite,
    wiggle2 ease-in-out 6715ms infinite;
}
.ray:nth-child(48) {
  left: 63%;
  height: 323px;
  margin-top: -100px;
  width: 4px;
  background: #ff0066;
  -webkit-filter: blur(9.4px);
  -khtml-filter: blur(9.4px);
  -moz-filter: blur(9.4px);
  -ms-filter: blur(9.4px);
  -o-filter: blur(9.4px);
  filter: blur(9.4px);
  -webkit-animation: sml-fade ease-in-out 6086ms infinite,
    wiggle1 ease-in-out 6714ms infinite;
  animation: sml-fade ease-in-out 6086ms infinite,
    wiggle1 ease-in-out 6714ms infinite;
}
.ray:nth-child(49) {
  left: 46%;
  height: 101px;
  margin-top: -100px;
  width: 3px;
  background: #ff0048;
  -webkit-filter: blur(8.4px);
  -khtml-filter: blur(8.4px);
  -moz-filter: blur(8.4px);
  -ms-filter: blur(8.4px);
  -o-filter: blur(8.4px);
  filter: blur(8.4px);
  -webkit-animation: sml-fade ease-in-out 6822ms infinite,
    wiggle2 ease-in-out 6153ms infinite;
  animation: sml-fade ease-in-out 6822ms infinite,
    wiggle2 ease-in-out 6153ms infinite;
}
.ray:nth-child(50) {
  left: 98%;
  height: 240px;
  margin-top: -100px;
  width: 4px;
  background: #ff002b;
  -webkit-filter: blur(12.4px);
  -khtml-filter: blur(12.4px);
  -moz-filter: blur(12.4px);
  -ms-filter: blur(12.4px);
  -o-filter: blur(12.4px);
  filter: blur(12.4px);
  -webkit-animation: sml-fade ease-in-out 6613ms infinite,
    wiggle3 ease-in-out 6327ms infinite;
  animation: sml-fade ease-in-out 6613ms infinite,
    wiggle3 ease-in-out 6327ms infinite;
}
.ray:nth-child(51) {
  left: 75%;
  height: 178px;
  width: 16px;
  background: #3300ff;
  -webkit-filter: blur(19.32px);
  -khtml-filter: blur(19.32px);
  -moz-filter: blur(19.32px);
  -ms-filter: blur(19.32px);
  -o-filter: blur(19.32px);
  filter: blur(19.32px);
  -webkit-animation: med-fade ease-in-out 6411ms infinite,
    wiggle3 ease-in-out 7715ms infinite;
  animation: med-fade ease-in-out 6411ms infinite,
    wiggle3 ease-in-out 7715ms infinite;
}
.ray:nth-child(52) {
  left: 63%;
  height: 230px;
  width: 30px;
  background: #6600ff;
  -webkit-filter: blur(38.1px);
  -khtml-filter: blur(38.1px);
  -moz-filter: blur(38.1px);
  -ms-filter: blur(38.1px);
  -o-filter: blur(38.1px);
  filter: blur(38.1px);
  -webkit-animation: med-fade ease-in-out 6788ms infinite,
    wiggle3 ease-in-out 8401ms infinite;
  animation: med-fade ease-in-out 6788ms infinite,
    wiggle3 ease-in-out 8401ms infinite;
}
.ray:nth-child(53) {
  left: 51%;
  height: 269px;
  width: 17px;
  background: #9900ff;
  -webkit-filter: blur(26.09px);
  -khtml-filter: blur(26.09px);
  -moz-filter: blur(26.09px);
  -ms-filter: blur(26.09px);
  -o-filter: blur(26.09px);
  filter: blur(26.09px);
  -webkit-animation: med-fade ease-in-out 6491ms infinite,
    wiggle2 ease-in-out 14592ms infinite;
  animation: med-fade ease-in-out 6491ms infinite,
    wiggle2 ease-in-out 14592ms infinite;
}
.ray:nth-child(54) {
  left: 81%;
  height: 238px;
  width: 19px;
  background: #cc00ff;
  -webkit-filter: blur(18.63px);
  -khtml-filter: blur(18.63px);
  -moz-filter: blur(18.63px);
  -ms-filter: blur(18.63px);
  -o-filter: blur(18.63px);
  filter: blur(18.63px);
  -webkit-animation: med-fade ease-in-out 6214ms infinite,
    wiggle3 ease-in-out 14582ms infinite;
  animation: med-fade ease-in-out 6214ms infinite,
    wiggle3 ease-in-out 14582ms infinite;
}
.ray:nth-child(55) {
  left: 15%;
  height: 203px;
  width: 27px;
  background: fuchsia;
  -webkit-filter: blur(28.79px);
  -khtml-filter: blur(28.79px);
  -moz-filter: blur(28.79px);
  -ms-filter: blur(28.79px);
  -o-filter: blur(28.79px);
  filter: blur(28.79px);
  -webkit-animation: med-fade ease-in-out 6929ms infinite,
    wiggle2 ease-in-out 10790ms infinite;
  animation: med-fade ease-in-out 6929ms infinite,
    wiggle2 ease-in-out 10790ms infinite;
}
.ray:nth-child(56) {
  left: 55%;
  height: 329px;
  width: 29px;
  background: #ff00cc;
  -webkit-filter: blur(35.33px);
  -khtml-filter: blur(35.33px);
  -moz-filter: blur(35.33px);
  -ms-filter: blur(35.33px);
  -o-filter: blur(35.33px);
  filter: blur(35.33px);
  -webkit-animation: med-fade ease-in-out 6445ms infinite,
    wiggle3 ease-in-out 9967ms infinite;
  animation: med-fade ease-in-out 6445ms infinite,
    wiggle3 ease-in-out 9967ms infinite;
}
.ray:nth-child(57) {
  left: 23%;
  height: 377px;
  width: 27px;
  background: #ff0099;
  -webkit-filter: blur(47.79px);
  -khtml-filter: blur(47.79px);
  -moz-filter: blur(47.79px);
  -ms-filter: blur(47.79px);
  -o-filter: blur(47.79px);
  filter: blur(47.79px);
  -webkit-animation: med-fade ease-in-out 6437ms infinite,
    wiggle2 ease-in-out 14163ms infinite;
  animation: med-fade ease-in-out 6437ms infinite,
    wiggle2 ease-in-out 14163ms infinite;
}
.ray:nth-child(58) {
  left: 52%;
  height: 143px;
  width: 23px;
  background: #ff0066;
  -webkit-filter: blur(36.71px);
  -khtml-filter: blur(36.71px);
  -moz-filter: blur(36.71px);
  -ms-filter: blur(36.71px);
  -o-filter: blur(36.71px);
  filter: blur(36.71px);
  -webkit-animation: med-fade ease-in-out 6712ms infinite,
    wiggle2 ease-in-out 7178ms infinite;
  animation: med-fade ease-in-out 6712ms infinite,
    wiggle2 ease-in-out 7178ms infinite;
}
.ray:nth-child(59) {
  left: 10%;
  height: 388px;
  width: 20px;
  background: #ff0033;
  -webkit-filter: blur(30.4px);
  -khtml-filter: blur(30.4px);
  -moz-filter: blur(30.4px);
  -ms-filter: blur(30.4px);
  -o-filter: blur(30.4px);
  filter: blur(30.4px);
  -webkit-animation: med-fade ease-in-out 6739ms infinite,
    wiggle3 ease-in-out 7430ms infinite;
  animation: med-fade ease-in-out 6739ms infinite,
    wiggle3 ease-in-out 7430ms infinite;
}
.ray:nth-child(60) {
  left: 22%;
  height: 372px;
  width: 30px;
  background: red;
  -webkit-filter: blur(39.1px);
  -khtml-filter: blur(39.1px);
  -moz-filter: blur(39.1px);
  -ms-filter: blur(39.1px);
  -o-filter: blur(39.1px);
  filter: blur(39.1px);
  -webkit-animation: med-fade ease-in-out 6341ms infinite,
    wiggle2 ease-in-out 9967ms infinite;
  animation: med-fade ease-in-out 6341ms infinite,
    wiggle2 ease-in-out 9967ms infinite;
}
.ray:nth-child(61) {
  left: 11%;
  height: 427px;
  width: 26px;
  background: #ff3300;
  -webkit-filter: blur(31.02px);
  -khtml-filter: blur(31.02px);
  -moz-filter: blur(31.02px);
  -ms-filter: blur(31.02px);
  -o-filter: blur(31.02px);
  filter: blur(31.02px);
  -webkit-animation: med-fade ease-in-out 6373ms infinite,
    wiggle2 ease-in-out 12176ms infinite;
  animation: med-fade ease-in-out 6373ms infinite,
    wiggle2 ease-in-out 12176ms infinite;
}
.ray:nth-child(62) {
  left: 67%;
  height: 212px;
  width: 19px;
  background: #ff6600;
  -webkit-filter: blur(25.63px);
  -khtml-filter: blur(25.63px);
  -moz-filter: blur(25.63px);
  -ms-filter: blur(25.63px);
  -o-filter: blur(25.63px);
  filter: blur(25.63px);
  -webkit-animation: med-fade ease-in-out 6355ms infinite,
    wiggle3 ease-in-out 14166ms infinite;
  animation: med-fade ease-in-out 6355ms infinite,
    wiggle3 ease-in-out 14166ms infinite;
}
.ray:nth-child(63) {
  left: 26%;
  height: 370px;
  width: 21px;
  background: #ff9900;
  -webkit-filter: blur(24.17px);
  -khtml-filter: blur(24.17px);
  -moz-filter: blur(24.17px);
  -ms-filter: blur(24.17px);
  -o-filter: blur(24.17px);
  filter: blur(24.17px);
  -webkit-animation: med-fade ease-in-out 6896ms infinite,
    wiggle1 ease-in-out 14148ms infinite;
  animation: med-fade ease-in-out 6896ms infinite,
    wiggle1 ease-in-out 14148ms infinite;
}
.ray:nth-child(64) {
  left: 56%;
  height: 275px;
  width: 26px;
  background: #ffcc00;
  -webkit-filter: blur(30.02px);
  -khtml-filter: blur(30.02px);
  -moz-filter: blur(30.02px);
  -ms-filter: blur(30.02px);
  -o-filter: blur(30.02px);
  filter: blur(30.02px);
  -webkit-animation: med-fade ease-in-out 6013ms infinite,
    wiggle2 ease-in-out 7923ms infinite;
  animation: med-fade ease-in-out 6013ms infinite,
    wiggle2 ease-in-out 7923ms infinite;
}
.ray:nth-child(65) {
  left: 27%;
  height: 381px;
  width: 28px;
  background: yellow;
  -webkit-filter: blur(49.56px);
  -khtml-filter: blur(49.56px);
  -moz-filter: blur(49.56px);
  -ms-filter: blur(49.56px);
  -o-filter: blur(49.56px);
  filter: blur(49.56px);
  -webkit-animation: med-fade ease-in-out 6086ms infinite,
    wiggle2 ease-in-out 8316ms infinite;
  animation: med-fade ease-in-out 6086ms infinite,
    wiggle2 ease-in-out 8316ms infinite;
}
.ray:nth-child(66) {
  left: 58%;
  height: 378px;
  width: 27px;
  background: #ccff00;
  -webkit-filter: blur(22.79px);
  -khtml-filter: blur(22.79px);
  -moz-filter: blur(22.79px);
  -ms-filter: blur(22.79px);
  -o-filter: blur(22.79px);
  filter: blur(22.79px);
  -webkit-animation: med-fade ease-in-out 6806ms infinite,
    wiggle3 ease-in-out 12860ms infinite;
  animation: med-fade ease-in-out 6806ms infinite,
    wiggle3 ease-in-out 12860ms infinite;
}
.ray:nth-child(67) {
  left: 1%;
  height: 117px;
  width: 21px;
  background: #99ff00;
  -webkit-filter: blur(30.17px);
  -khtml-filter: blur(30.17px);
  -moz-filter: blur(30.17px);
  -ms-filter: blur(30.17px);
  -o-filter: blur(30.17px);
  filter: blur(30.17px);
  -webkit-animation: med-fade ease-in-out 6598ms infinite,
    wiggle1 ease-in-out 13782ms infinite;
  animation: med-fade ease-in-out 6598ms infinite,
    wiggle1 ease-in-out 13782ms infinite;
}
.ray:nth-child(68) {
  left: 18%;
  height: 264px;
  width: 22px;
  background: #66ff00;
  -webkit-filter: blur(37.94px);
  -khtml-filter: blur(37.94px);
  -moz-filter: blur(37.94px);
  -ms-filter: blur(37.94px);
  -o-filter: blur(37.94px);
  filter: blur(37.94px);
  -webkit-animation: med-fade ease-in-out 6281ms infinite,
    wiggle3 ease-in-out 11510ms infinite;
  animation: med-fade ease-in-out 6281ms infinite,
    wiggle3 ease-in-out 11510ms infinite;
}
.ray:nth-child(69) {
  left: 15%;
  height: 318px;
  width: 30px;
  background: #33ff00;
  -webkit-filter: blur(40.1px);
  -khtml-filter: blur(40.1px);
  -moz-filter: blur(40.1px);
  -ms-filter: blur(40.1px);
  -o-filter: blur(40.1px);
  filter: blur(40.1px);
  -webkit-animation: med-fade ease-in-out 6037ms infinite,
    wiggle2 ease-in-out 9759ms infinite;
  animation: med-fade ease-in-out 6037ms infinite,
    wiggle2 ease-in-out 9759ms infinite;
}
.ray:nth-child(70) {
  left: 4%;
  height: 292px;
  width: 28px;
  background: lime;
  -webkit-filter: blur(37.56px);
  -khtml-filter: blur(37.56px);
  -moz-filter: blur(37.56px);
  -ms-filter: blur(37.56px);
  -o-filter: blur(37.56px);
  filter: blur(37.56px);
  -webkit-animation: med-fade ease-in-out 6948ms infinite,
    wiggle1 ease-in-out 12423ms infinite;
  animation: med-fade ease-in-out 6948ms infinite,
    wiggle1 ease-in-out 12423ms infinite;
}
.ray:nth-child(71) {
  left: 79%;
  height: 268px;
  width: 16px;
  background: #00ff33;
  -webkit-filter: blur(28.32px);
  -khtml-filter: blur(28.32px);
  -moz-filter: blur(28.32px);
  -ms-filter: blur(28.32px);
  -o-filter: blur(28.32px);
  filter: blur(28.32px);
  -webkit-animation: med-fade ease-in-out 6907ms infinite,
    wiggle2 ease-in-out 13332ms infinite;
  animation: med-fade ease-in-out 6907ms infinite,
    wiggle2 ease-in-out 13332ms infinite;
}
.ray:nth-child(72) {
  left: 61%;
  height: 343px;
  width: 21px;
  background: #00ff66;
  -webkit-filter: blur(21.17px);
  -khtml-filter: blur(21.17px);
  -moz-filter: blur(21.17px);
  -ms-filter: blur(21.17px);
  -o-filter: blur(21.17px);
  filter: blur(21.17px);
  -webkit-animation: med-fade ease-in-out 6328ms infinite,
    wiggle1 ease-in-out 9730ms infinite;
  animation: med-fade ease-in-out 6328ms infinite,
    wiggle1 ease-in-out 9730ms infinite;
}
.ray:nth-child(73) {
  left: 96%;
  height: 177px;
  width: 18px;
  background: #00ff99;
  -webkit-filter: blur(30.86px);
  -khtml-filter: blur(30.86px);
  -moz-filter: blur(30.86px);
  -ms-filter: blur(30.86px);
  -o-filter: blur(30.86px);
  filter: blur(30.86px);
  -webkit-animation: med-fade ease-in-out 6180ms infinite,
    wiggle2 ease-in-out 6924ms infinite;
  animation: med-fade ease-in-out 6180ms infinite,
    wiggle2 ease-in-out 6924ms infinite;
}
.ray:nth-child(74) {
  left: 30%;
  height: 383px;
  width: 29px;
  background: #00ffcc;
  -webkit-filter: blur(44.33px);
  -khtml-filter: blur(44.33px);
  -moz-filter: blur(44.33px);
  -ms-filter: blur(44.33px);
  -o-filter: blur(44.33px);
  filter: blur(44.33px);
  -webkit-animation: med-fade ease-in-out 6033ms infinite,
    wiggle2 ease-in-out 11526ms infinite;
  animation: med-fade ease-in-out 6033ms infinite,
    wiggle2 ease-in-out 11526ms infinite;
}
.ray:nth-child(75) {
  left: 8%;
  height: 118px;
  width: 26px;
  background: aqua;
  -webkit-filter: blur(36.02px);
  -khtml-filter: blur(36.02px);
  -moz-filter: blur(36.02px);
  -ms-filter: blur(36.02px);
  -o-filter: blur(36.02px);
  filter: blur(36.02px);
  -webkit-animation: med-fade ease-in-out 6927ms infinite,
    wiggle3 ease-in-out 14882ms infinite;
  animation: med-fade ease-in-out 6927ms infinite,
    wiggle3 ease-in-out 14882ms infinite;
}
.ray:nth-child(76) {
  left: 84%;
  height: 114px;
  width: 27px;
  background: #00ccff;
  -webkit-filter: blur(47.79px);
  -khtml-filter: blur(47.79px);
  -moz-filter: blur(47.79px);
  -ms-filter: blur(47.79px);
  -o-filter: blur(47.79px);
  filter: blur(47.79px);
  -webkit-animation: med-fade ease-in-out 6932ms infinite,
    wiggle3 ease-in-out 8007ms infinite;
  animation: med-fade ease-in-out 6932ms infinite,
    wiggle3 ease-in-out 8007ms infinite;
}
.ray:nth-child(77) {
  left: 42%;
  height: 420px;
  width: 30px;
  background: #0099ff;
  -webkit-filter: blur(34.1px);
  -khtml-filter: blur(34.1px);
  -moz-filter: blur(34.1px);
  -ms-filter: blur(34.1px);
  -o-filter: blur(34.1px);
  filter: blur(34.1px);
  -webkit-animation: med-fade ease-in-out 6561ms infinite,
    wiggle2 ease-in-out 6427ms infinite;
  animation: med-fade ease-in-out 6561ms infinite,
    wiggle2 ease-in-out 6427ms infinite;
}
.ray:nth-child(78) {
  left: 41%;
  height: 286px;
  width: 16px;
  background: #0066ff;
  -webkit-filter: blur(13.32px);
  -khtml-filter: blur(13.32px);
  -moz-filter: blur(13.32px);
  -ms-filter: blur(13.32px);
  -o-filter: blur(13.32px);
  filter: blur(13.32px);
  -webkit-animation: med-fade ease-in-out 6840ms infinite,
    wiggle1 ease-in-out 14061ms infinite;
  animation: med-fade ease-in-out 6840ms infinite,
    wiggle1 ease-in-out 14061ms infinite;
}
.ray:nth-child(79) {
  left: 20%;
  height: 418px;
  width: 29px;
  background: #0033ff;
  -webkit-filter: blur(39.33px);
  -khtml-filter: blur(39.33px);
  -moz-filter: blur(39.33px);
  -ms-filter: blur(39.33px);
  -o-filter: blur(39.33px);
  filter: blur(39.33px);
  -webkit-animation: med-fade ease-in-out 6890ms infinite,
    wiggle2 ease-in-out 7045ms infinite;
  animation: med-fade ease-in-out 6890ms infinite,
    wiggle2 ease-in-out 7045ms infinite;
}
.ray:nth-child(80) {
  left: 53%;
  height: 276px;
  width: 25px;
  background: blue;
  -webkit-filter: blur(23.25px);
  -khtml-filter: blur(23.25px);
  -moz-filter: blur(23.25px);
  -ms-filter: blur(23.25px);
  -o-filter: blur(23.25px);
  filter: blur(23.25px);
  -webkit-animation: med-fade ease-in-out 6477ms infinite,
    wiggle1 ease-in-out 6436ms infinite;
  animation: med-fade ease-in-out 6477ms infinite,
    wiggle1 ease-in-out 6436ms infinite;
}
.ray:nth-child(81) {
  left: 83%;
  height: 197px;
  width: 78px;
  background: #ff4d00;
  -webkit-filter: blur(108.9px);
  -khtml-filter: blur(108.9px);
  -moz-filter: blur(108.9px);
  -ms-filter: blur(108.9px);
  -o-filter: blur(108.9px);
  filter: blur(108.9px);
  -webkit-animation: big-fade ease-in-out 6085ms infinite,
    wiggle2 ease-in-out 12857ms infinite;
  animation: big-fade ease-in-out 6085ms infinite,
    wiggle2 ease-in-out 12857ms infinite;
}
.ray:nth-child(82) {
  left: 47%;
  height: 323px;
  width: 70px;
  background: #ff9900;
  -webkit-filter: blur(44.5px);
  -khtml-filter: blur(44.5px);
  -moz-filter: blur(44.5px);
  -ms-filter: blur(44.5px);
  -o-filter: blur(44.5px);
  filter: blur(44.5px);
  -webkit-animation: big-fade ease-in-out 6980ms infinite,
    wiggle2 ease-in-out 14314ms infinite;
  animation: big-fade ease-in-out 6980ms infinite,
    wiggle2 ease-in-out 14314ms infinite;
}
.ray:nth-child(83) {
  left: 73%;
  height: 244px;
  width: 69px;
  background: #ffe600;
  -webkit-filter: blur(104.95px);
  -khtml-filter: blur(104.95px);
  -moz-filter: blur(104.95px);
  -ms-filter: blur(104.95px);
  -o-filter: blur(104.95px);
  filter: blur(104.95px);
  -webkit-animation: big-fade ease-in-out 6470ms infinite,
    wiggle2 ease-in-out 7402ms infinite;
  animation: big-fade ease-in-out 6470ms infinite,
    wiggle2 ease-in-out 7402ms infinite;
}
.ray:nth-child(84) {
  left: 3%;
  height: 195px;
  width: 58px;
  background: #ccff00;
  -webkit-filter: blur(73.9px);
  -khtml-filter: blur(73.9px);
  -moz-filter: blur(73.9px);
  -ms-filter: blur(73.9px);
  -o-filter: blur(73.9px);
  filter: blur(73.9px);
  -webkit-animation: big-fade ease-in-out 6948ms infinite,
    wiggle2 ease-in-out 7831ms infinite;
  animation: big-fade ease-in-out 6948ms infinite,
    wiggle2 ease-in-out 7831ms infinite;
}
.ray:nth-child(85) {
  left: 64%;
  height: 241px;
  width: 66px;
  background: #80ff00;
  -webkit-filter: blur(79.3px);
  -khtml-filter: blur(79.3px);
  -moz-filter: blur(79.3px);
  -ms-filter: blur(79.3px);
  -o-filter: blur(79.3px);
  filter: blur(79.3px);
  -webkit-animation: big-fade ease-in-out 6038ms infinite,
    wiggle1 ease-in-out 8597ms infinite;
  animation: big-fade ease-in-out 6038ms infinite,
    wiggle1 ease-in-out 8597ms infinite;
}
.ray:nth-child(86) {
  left: 5%;
  height: 137px;
  width: 46px;
  background: #33ff00;
  -webkit-filter: blur(32.3px);
  -khtml-filter: blur(32.3px);
  -moz-filter: blur(32.3px);
  -ms-filter: blur(32.3px);
  -o-filter: blur(32.3px);
  filter: blur(32.3px);
  -webkit-animation: big-fade ease-in-out 6958ms infinite,
    wiggle3 ease-in-out 11682ms infinite;
  animation: big-fade ease-in-out 6958ms infinite,
    wiggle3 ease-in-out 11682ms infinite;
}
.ray:nth-child(87) {
  left: 26%;
  height: 380px;
  width: 73px;
  background: #00ff1a;
  -webkit-filter: blur(83.15px);
  -khtml-filter: blur(83.15px);
  -moz-filter: blur(83.15px);
  -ms-filter: blur(83.15px);
  -o-filter: blur(83.15px);
  filter: blur(83.15px);
  -webkit-animation: big-fade ease-in-out 6315ms infinite,
    wiggle2 ease-in-out 15058ms infinite;
  animation: big-fade ease-in-out 6315ms infinite,
    wiggle2 ease-in-out 15058ms infinite;
}
.ray:nth-child(88) {
  left: 81%;
  height: 315px;
  width: 58px;
  background: #00ff66;
  -webkit-filter: blur(56.9px);
  -khtml-filter: blur(56.9px);
  -moz-filter: blur(56.9px);
  -ms-filter: blur(56.9px);
  -o-filter: blur(56.9px);
  filter: blur(56.9px);
  -webkit-animation: big-fade ease-in-out 6328ms infinite,
    wiggle1 ease-in-out 10822ms infinite;
  animation: big-fade ease-in-out 6328ms infinite,
    wiggle1 ease-in-out 10822ms infinite;
}
.ray:nth-child(89) {
  left: 59%;
  height: 104px;
  width: 57px;
  background: #00ffb3;
  -webkit-filter: blur(36.35px);
  -khtml-filter: blur(36.35px);
  -moz-filter: blur(36.35px);
  -ms-filter: blur(36.35px);
  -o-filter: blur(36.35px);
  filter: blur(36.35px);
  -webkit-animation: big-fade ease-in-out 6445ms infinite,
    wiggle1 ease-in-out 14982ms infinite;
  animation: big-fade ease-in-out 6445ms infinite,
    wiggle1 ease-in-out 14982ms infinite;
}
.ray:nth-child(90) {
  left: 91%;
  height: 294px;
  width: 51px;
  background: aqua;
  -webkit-filter: blur(38.05px);
  -khtml-filter: blur(38.05px);
  -moz-filter: blur(38.05px);
  -ms-filter: blur(38.05px);
  -o-filter: blur(38.05px);
  filter: blur(38.05px);
  -webkit-animation: big-fade ease-in-out 6307ms infinite,
    wiggle3 ease-in-out 6771ms infinite;
  animation: big-fade ease-in-out 6307ms infinite,
    wiggle3 ease-in-out 6771ms infinite;
}
.ray:nth-child(91) {
  left: 2%;
  height: 141px;
  width: 51px;
  background: #00b3ff;
  -webkit-filter: blur(61.05px);
  -khtml-filter: blur(61.05px);
  -moz-filter: blur(61.05px);
  -ms-filter: blur(61.05px);
  -o-filter: blur(61.05px);
  filter: blur(61.05px);
  -webkit-animation: big-fade ease-in-out 6830ms infinite,
    wiggle2 ease-in-out 11771ms infinite;
  animation: big-fade ease-in-out 6830ms infinite,
    wiggle2 ease-in-out 11771ms infinite;
}
.ray:nth-child(92) {
  left: 18%;
  height: 442px;
  width: 51px;
  background: #0066ff;
  -webkit-filter: blur(29.05px);
  -khtml-filter: blur(29.05px);
  -moz-filter: blur(29.05px);
  -ms-filter: blur(29.05px);
  -o-filter: blur(29.05px);
  filter: blur(29.05px);
  -webkit-animation: big-fade ease-in-out 6075ms infinite,
    wiggle1 ease-in-out 15093ms infinite;
  animation: big-fade ease-in-out 6075ms infinite,
    wiggle1 ease-in-out 15093ms infinite;
}
.ray:nth-child(93) {
  left: 48%;
  height: 283px;
  width: 78px;
  background: #001aff;
  -webkit-filter: blur(62.9px);
  -khtml-filter: blur(62.9px);
  -moz-filter: blur(62.9px);
  -ms-filter: blur(62.9px);
  -o-filter: blur(62.9px);
  filter: blur(62.9px);
  -webkit-animation: big-fade ease-in-out 6775ms infinite,
    wiggle3 ease-in-out 10284ms infinite;
  animation: big-fade ease-in-out 6775ms infinite,
    wiggle3 ease-in-out 10284ms infinite;
}
.ray:nth-child(94) {
  left: 37%;
  height: 334px;
  width: 68px;
  background: #3300ff;
  -webkit-filter: blur(103.4px);
  -khtml-filter: blur(103.4px);
  -moz-filter: blur(103.4px);
  -ms-filter: blur(103.4px);
  -o-filter: blur(103.4px);
  filter: blur(103.4px);
  -webkit-animation: big-fade ease-in-out 6532ms infinite,
    wiggle2 ease-in-out 12004ms infinite;
  animation: big-fade ease-in-out 6532ms infinite,
    wiggle2 ease-in-out 12004ms infinite;
}
.ray:nth-child(95) {
  left: 88%;
  height: 264px;
  width: 60px;
  background: #8000ff;
  -webkit-filter: blur(68px);
  -khtml-filter: blur(68px);
  -moz-filter: blur(68px);
  -ms-filter: blur(68px);
  -o-filter: blur(68px);
  filter: blur(68px);
  -webkit-animation: big-fade ease-in-out 6707ms infinite,
    wiggle1 ease-in-out 8259ms infinite;
  animation: big-fade ease-in-out 6707ms infinite,
    wiggle1 ease-in-out 8259ms infinite;
}
.ray:nth-child(96) {
  left: 74%;
  height: 276px;
  width: 55px;
  background: #cc00ff;
  -webkit-filter: blur(58.25px);
  -khtml-filter: blur(58.25px);
  -moz-filter: blur(58.25px);
  -ms-filter: blur(58.25px);
  -o-filter: blur(58.25px);
  filter: blur(58.25px);
  -webkit-animation: big-fade ease-in-out 6390ms infinite,
    wiggle2 ease-in-out 7127ms infinite;
  animation: big-fade ease-in-out 6390ms infinite,
    wiggle2 ease-in-out 7127ms infinite;
}
.ray:nth-child(97) {
  left: 85%;
  height: 181px;
  width: 64px;
  background: #ff00e6;
  -webkit-filter: blur(88.2px);
  -khtml-filter: blur(88.2px);
  -moz-filter: blur(88.2px);
  -ms-filter: blur(88.2px);
  -o-filter: blur(88.2px);
  filter: blur(88.2px);
  -webkit-animation: big-fade ease-in-out 6999ms infinite,
    wiggle3 ease-in-out 14349ms infinite;
  animation: big-fade ease-in-out 6999ms infinite,
    wiggle3 ease-in-out 14349ms infinite;
}
.ray:nth-child(98) {
  left: 38%;
  height: 301px;
  width: 43px;
  background: #ff0099;
  -webkit-filter: blur(58.65px);
  -khtml-filter: blur(58.65px);
  -moz-filter: blur(58.65px);
  -ms-filter: blur(58.65px);
  -o-filter: blur(58.65px);
  filter: blur(58.65px);
  -webkit-animation: big-fade ease-in-out 6520ms infinite,
    wiggle1 ease-in-out 6881ms infinite;
  animation: big-fade ease-in-out 6520ms infinite,
    wiggle1 ease-in-out 6881ms infinite;
}
.ray:nth-child(99) {
  left: 13%;
  height: 167px;
  width: 43px;
  background: #ff004d;
  -webkit-filter: blur(57.65px);
  -khtml-filter: blur(57.65px);
  -moz-filter: blur(57.65px);
  -ms-filter: blur(57.65px);
  -o-filter: blur(57.65px);
  filter: blur(57.65px);
  -webkit-animation: big-fade ease-in-out 6094ms infinite,
    wiggle1 ease-in-out 7084ms infinite;
  animation: big-fade ease-in-out 6094ms infinite,
    wiggle1 ease-in-out 7084ms infinite;
}
.ray:nth-child(100) {
  left: 11%;
  height: 282px;
  width: 42px;
  background: red;
  -webkit-filter: blur(55.1px);
  -khtml-filter: blur(55.1px);
  -moz-filter: blur(55.1px);
  -ms-filter: blur(55.1px);
  -o-filter: blur(55.1px);
  filter: blur(55.1px);
  -webkit-animation: big-fade ease-in-out 6136ms infinite,
    wiggle3 ease-in-out 11453ms infinite;
  animation: big-fade ease-in-out 6136ms infinite,
    wiggle3 ease-in-out 11453ms infinite;
}

@-webkit-keyframes sml-fade {
  0% {
    opacity: 0.15;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes sml-fade {
  0% {
    opacity: 0.15;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes med-fade {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.15;
  }
}
@keyframes med-fade {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.15;
  }
}
@-webkit-keyframes big-fade {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}
@keyframes big-fade {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}
@-webkit-keyframes wiggle0 {
  0% {
    -webkit-transform: translate3d(79px, -100px, 0);
    -khtml-transform: translate3d(79px, -100px, 0);
    transform: translate3d(79px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(88px, 88px, 0);
    -khtml-transform: translate3d(88px, 88px, 0);
    transform: translate3d(88px, 88px, 0);
    -webkit-transform: scaleX(1.5);
  }
  100% {
    -webkit-transform: translate3d(79px, -100px, 0);
    -khtml-transform: translate3d(79px, -100px, 0);
    transform: translate3d(79px, -100px, 0);
  }
}
@keyframes wiggle0 {
  0% {
    -webkit-transform: translate3d(79px, -100px, 0);
    -khtml-transform: translate3d(79px, -100px, 0);
    transform: translate3d(79px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(88px, 88px, 0);
    -khtml-transform: translate3d(88px, 88px, 0);
    transform: translate3d(88px, 88px, 0);
    -webkit-transform: scaleX(1.5);
  }
  100% {
    -webkit-transform: translate3d(79px, -100px, 0);
    -khtml-transform: translate3d(79px, -100px, 0);
    transform: translate3d(79px, -100px, 0);
  }
}
@-webkit-keyframes wiggle1 {
  0% {
    -webkit-transform: translate3d(1px, -100px, 0);
    -khtml-transform: translate3d(1px, -100px, 0);
    transform: translate3d(1px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(136px, 136px, 0);
    -khtml-transform: translate3d(136px, 136px, 0);
    transform: translate3d(136px, 136px, 0);
    -webkit-transform: scaleX(2);
  }
  100% {
    -webkit-transform: translate3d(1px, -100px, 0);
    -khtml-transform: translate3d(1px, -100px, 0);
    transform: translate3d(1px, -100px, 0);
  }
}
@keyframes wiggle1 {
  0% {
    -webkit-transform: translate3d(1px, -100px, 0);
    -khtml-transform: translate3d(1px, -100px, 0);
    transform: translate3d(1px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(136px, 136px, 0);
    -khtml-transform: translate3d(136px, 136px, 0);
    transform: translate3d(136px, 136px, 0);
    -webkit-transform: scaleX(2);
  }
  100% {
    -webkit-transform: translate3d(1px, -100px, 0);
    -khtml-transform: translate3d(1px, -100px, 0);
    transform: translate3d(1px, -100px, 0);
  }
}
@-webkit-keyframes wiggle2 {
  0% {
    -webkit-transform: translate3d(-37px, -100px, 0);
    -khtml-transform: translate3d(-37px, -100px, 0);
    transform: translate3d(-37px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(-19px, -19px, 0);
    -khtml-transform: translate3d(-19px, -19px, 0);
    transform: translate3d(-19px, -19px, 0);
    -webkit-transform: scaleX(2.5);
  }
  100% {
    -webkit-transform: translate3d(-37px, -100px, my3, 0);
    -khtml-transform: translate3d(-37px, -100px, my3, 0);
    transform: translate3d(-37px, -100px, my3, 0);
  }
}
@keyframes wiggle2 {
  0% {
    -webkit-transform: translate3d(-37px, -100px, 0);
    -khtml-transform: translate3d(-37px, -100px, 0);
    transform: translate3d(-37px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(-19px, -19px, 0);
    -khtml-transform: translate3d(-19px, -19px, 0);
    transform: translate3d(-19px, -19px, 0);
    -webkit-transform: scaleX(2.5);
  }
  100% {
    -webkit-transform: translate3d(-37px, -100px, my3, 0);
    -khtml-transform: translate3d(-37px, -100px, my3, 0);
    transform: translate3d(-37px, -100px, my3, 0);
  }
}
@-webkit-keyframes wiggle3 {
  0% {
    -webkit-transform: translate3d(-78px, -100px, 0);
    -khtml-transform: translate3d(-78px, -100px, 0);
    transform: translate3d(-78px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(-65px, 65px, 0);
    -khtml-transform: translate3d(-65px, 65px, 0);
    transform: translate3d(-65px, 65px, 0);
    -webkit-transform: scaleX(3);
  }
  100% {
    -webkit-transform: translate3d(-78px, -100px, 0);
    -khtml-transform: translate3d(-78px, -100px, 0);
    transform: translate3d(-78px, -100px, 0);
  }
}
@keyframes wiggle3 {
  0% {
    -webkit-transform: translate3d(-78px, -100px, 0);
    -khtml-transform: translate3d(-78px, -100px, 0);
    transform: translate3d(-78px, -100px, 0);
  }
  50% {
    -webkit-transform: translate3d(-65px, 65px, 0);
    -khtml-transform: translate3d(-65px, 65px, 0);
    transform: translate3d(-65px, 65px, 0);
    -webkit-transform: scaleX(3);
  }
  100% {
    -webkit-transform: translate3d(-78px, -100px, 0);
    -khtml-transform: translate3d(-78px, -100px, 0);
    transform: translate3d(-78px, -100px, 0);
  }
}
@-webkit-keyframes light-field {
  from {
    -ms-box-shadow: 0 0 280px 105px #4fffe1;
    -o-box-shadow: 0 0 280px 105px #4fffe1;
    box-shadow: 0 0 280px 105px #4fffe1;
  }
  to {
    -ms-box-shadow: 0 0 210px 35px #4fffe1;
    -o-box-shadow: 0 0 210px 35px #4fffe1;
    box-shadow: 0 0 210px 35px #4fffe1;
  }
}
@keyframes light-field {
  from {
    -ms-box-shadow: 0 0 280px 105px #4fffe1;
    -o-box-shadow: 0 0 280px 105px #4fffe1;
    box-shadow: 0 0 280px 105px #4fffe1;
  }
  to {
    -ms-box-shadow: 0 0 210px 35px #4fffe1;
    -o-box-shadow: 0 0 210px 35px #4fffe1;
    box-shadow: 0 0 210px 35px #4fffe1;
  }
}

@media screen and (max-width: 760px) {
  .offcanvas {
    background-color: #00000047 !important;
    width: 60% !important;
  }
}

.offcanvas {
  background-color: #00000047 !important;
  width: 30%;
}
.btn-close {
  background-image: url(/static/media/close.c0f372be.svg) !important;
}

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #000000 !important;
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

iframe {
  display: none !important;
}

@font-face {
  font-family: "Aparajita";
  src: local("Aparajita"),
    url(/static/media/aparaji.176b1f58.ttf) format("truetype");
}

#scroll-text {
  height: 100%;
  text-align: center;
  /* animation properties */
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-animation: my-animation 20s linear infinite;
  animation: my-animation 20s linear infinite;
}

/* for Firefox */

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.drawer {
  position: fixed;
  top: 0;
  right: -300px; /* Set the initial position outside of the screen */
  width: 300px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  transition: right 0.3s ease-in-out;
}

.drawer.open {
  right: 0; /* Animate the drawer into view */
}

.marquee-container {
  height: 20vh; /* set the height of the container element */
  /* overflow: hidden;  */
  /* hide the overflow of the container element */
}

.marquee-text {
  display: flex; /* display the text element as a flex container */
  justify-content: center; /* center the text horizontally */
}

/* .marquee-text:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
  cursor: pointer;
} */

.hamburger {
  background: transparent;
  border: none;
  color: rgb(244, 172, 11);
  font-size: 3.5vh;
  z-index: 100;
  position: absolute;
  top: 2%;
  right: 2%;
}

.hamburger:hover {
  cursor: pointer;
}

.cross {
  background: transparent;
  border: none;
  color: rgb(244, 172, 11);
  position: absolute;
  top: 2vh;
  font-size: 30px !important;
}
.cross:hover {
  cursor: pointer;
}

.links {
  padding: 5vh 5vw;
  color: white;
  font-size: 2.5vh;
  display: flex;
  flex-direction: column;
}

.ham-link:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.langtext_div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: "center";
}
.lang_img {
  height: 350px;
  -webkit-animation: spin 50s linear infinite;
  animation: spin 50s linear infinite;
}
.lang_img :hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.english_lang {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 3vh;
  line-height: 27px;
  display: contents;
  text-align: center;
  color: #ffffff;
  border: 1px solid #000000;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  /* -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black; */
}
.hindi_lang {
  font-family: "Aparajita";
  font-style: normal;
  font-weight: 900;
  font-size: 3vh;
  line-height: 33px;
  text-align: center;
  display: contents;
  color: #ffffff;
  border: 1px solid #000000;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  /* -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black; */
}
.marquee-text {
  display: flex; /* display the text element as a flex container */
  justify-content: center; /* center the text horizontally */
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%); /* start the animation with no offset */
  }
  100% {
    -webkit-transform: translateY(
      -100%
    );
            transform: translateY(
      -100%
    ); /* end the animation with an offset of -100% (i.e. move the text all the way up) */
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%); /* start the animation with no offset */
  }
  100% {
    -webkit-transform: translateY(
      -100%
    );
            transform: translateY(
      -100%
    ); /* end the animation with an offset of -100% (i.e. move the text all the way up) */
  }
}

@-webkit-keyframes marquee-text {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); /* start the animation with no offset */
  }
  to {
    -webkit-transform: translate3d(
      0,
      -100%,
      0
    );
            transform: translate3d(
      0,
      -100%,
      0
    ); /* end the animation with an offset of -100% (i.e. move the text all the way up) */
  }
}

@keyframes marquee-text {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); /* start the animation with no offset */
  }
  to {
    -webkit-transform: translate3d(
      0,
      -100%,
      0
    );
            transform: translate3d(
      0,
      -100%,
      0
    ); /* end the animation with an offset of -100% (i.e. move the text all the way up) */
  }
}
/* for phones */
@media screen and (max-width: 480px) {
  .marquee-text {
    -webkit-animation: marquee 80s linear infinite;
            animation: marquee 80s linear infinite; /* define the animation */
  }
}

/* for tablets */
@media screen and (min-width: 481px) {
  .marquee-text {
    -webkit-animation: marquee 70s linear infinite;
            animation: marquee 70s linear infinite; /* define the animation */
  }
}
@media screen and (min-width: 769px) {
  .marquee-text {
    -webkit-animation: marquee 40s linear infinite;
            animation: marquee 40s linear infinite; /* define the animation */
  }
}

