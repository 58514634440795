@import url("https://fonts.googleapis.com/css2?family=Arya&family=Inter&family=Montserrat:wght@400;700&family=Playfair+Display:wght@700&family=Poppins:wght@400;500;700&display=swap");
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #000000 !important;
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

iframe {
  display: none !important;
}

@font-face {
  font-family: "Aparajita";
  src: local("Aparajita"),
    url(/src/static/fonts/aparajita/aparaji.ttf) format("truetype");
}

#scroll-text {
  height: 100%;
  text-align: center;
  /* animation properties */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation 20s linear infinite;
  -webkit-animation: my-animation 20s linear infinite;
  animation: my-animation 20s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.drawer {
  position: fixed;
  top: 0;
  right: -300px; /* Set the initial position outside of the screen */
  width: 300px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  transition: right 0.3s ease-in-out;
}

.drawer.open {
  right: 0; /* Animate the drawer into view */
}

.marquee-container {
  height: 20vh; /* set the height of the container element */
  /* overflow: hidden;  */
  /* hide the overflow of the container element */
}

.marquee-text {
  display: flex; /* display the text element as a flex container */
  justify-content: center; /* center the text horizontally */
}

/* .marquee-text:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
  cursor: pointer;
} */

.hamburger {
  background: transparent;
  border: none;
  color: rgb(244, 172, 11);
  font-size: 3.5vh;
  z-index: 100;
  position: absolute;
  top: 2%;
  right: 2%;
}

.hamburger:hover {
  cursor: pointer;
}

.cross {
  background: transparent;
  border: none;
  color: rgb(244, 172, 11);
  position: absolute;
  top: 2vh;
  font-size: 30px !important;
}
.cross:hover {
  cursor: pointer;
}

.links {
  padding: 5vh 5vw;
  color: white;
  font-size: 2.5vh;
  display: flex;
  flex-direction: column;
}

.ham-link:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.langtext_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: "center";
}
.lang_img {
  height: 350px;
  -webkit-animation: spin 50s linear infinite;
  -moz-animation: spin 50s linear infinite;
  animation: spin 50s linear infinite;
}
.lang_img :hover {
  animation-play-state: paused;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.english_lang {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 3vh;
  line-height: 27px;
  display: contents;
  text-align: center;
  color: #ffffff;
  border: 1px solid #000000;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  /* -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black; */
}
.hindi_lang {
  font-family: "Aparajita";
  font-style: normal;
  font-weight: 900;
  font-size: 3vh;
  line-height: 33px;
  text-align: center;
  display: contents;
  color: #ffffff;
  border: 1px solid #000000;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  /* -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black; */
}
.marquee-text {
  display: flex; /* display the text element as a flex container */
  justify-content: center; /* center the text horizontally */
}

@keyframes marquee {
  0% {
    transform: translateY(20%); /* start the animation with no offset */
  }
  100% {
    transform: translateY(
      -100%
    ); /* end the animation with an offset of -100% (i.e. move the text all the way up) */
  }
}

@keyframes marquee-text {
  from {
    transform: translate3d(0, 0, 0); /* start the animation with no offset */
  }
  to {
    transform: translate3d(
      0,
      -100%,
      0
    ); /* end the animation with an offset of -100% (i.e. move the text all the way up) */
  }
}
/* for phones */
@media screen and (max-width: 480px) {
  .marquee-text {
    animation: marquee 80s linear infinite; /* define the animation */
  }
}

/* for tablets */
@media screen and (min-width: 481px) {
  .marquee-text {
    animation: marquee 70s linear infinite; /* define the animation */
  }
}
@media screen and (min-width: 769px) {
  .marquee-text {
    animation: marquee 40s linear infinite; /* define the animation */
  }
}
